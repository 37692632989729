import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, InputLabel, MenuItem, FormControl, Select, ListItemText, TextField, Divider, AppBar, Toolbar, IconButton, Typography, Slide } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import MediaSearch from "./MediaSearch";
import { api } from "../core";

const validationSchema = yup.object({
  media: yup
    .mixed()
    .required("Required"),
  description: yup
    .string()
    .min(8, "Description should be at least 8 characters")
    .required("Required"),
});

const AddRecDialog = ({ typeId, rec, index, open, handleClose }) => {
  const handleCancel = () => {
    handleClose(null);
  };

  const formik = useFormik({
    initialValues: {
      media: rec ? {mediaId: rec.mediaId, title: rec.title} : null,
      description: rec ? rec.description : "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const newRec = await api.addRec(rec ? rec.mediaId : null, values.media.mediaId, values.description);

      resetForm();
      handleClose(newRec, index);

      setSubmitting(false);
    },
  });

  const dialogTitle = rec ? "Edit Rec" : "Add Rec";
  const submitButtonText = rec ? "Edit" : "Add";
  const searchPlaceholderText = typeId === "book" ? "Search for a book" : "Search for a podcast";
  const descriptionPlaceholderText = typeId === "book" ? "Why are you recommending this book?" : "Why are you recommending this podcast?";

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MediaSearch
            autoFocus
            id="media"
            name="media"
            margin="normal"
            placeholder={searchPlaceholderText}
            fullWidth
            typeId={typeId}
            value2={formik.values.media}
            onChange={formik.handleChange}
            error={formik.touched.media && Boolean(formik.errors.media)}
            helperText={formik.touched.media && formik.errors.media}
          />
          <TextField
            id="description"
            name="description"
            margin="normal"
            placeholder={descriptionPlaceholderText}
            fullWidth
            multiline
            minRows={8}
            maxRows={16}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} disabled={formik.isSubmitting}>Cancel</Button>
          <Button type="submit" disabled={formik.isSubmitting}>{submitButtonText}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddRecDialog;