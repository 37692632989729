export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_IS_LOADING_INSTAGRAM_PHOTO = "SET_IS_LOADING_INSTAGRAM_PHOTO";

export const setIsAuthenticated = (isAuthenticated, identityId) => {
  return (dispatch, getState) => {
    if (isAuthenticated !== getState().memory.auth.isAuthenticated) {
      return dispatch({
        type: SET_IS_AUTHENTICATED,
        isAuthenticated,
        identityId,
      });
    } else {
      return Promise.resolve();
    }
  };
};

export const setIsLoadingInstagramPhoto = isLoadingInstagramPhoto => {
  return (dispatch, getState) => {
    if (isLoadingInstagramPhoto !== getState().memory.photo.isLoadingInstagramPhoto) {
      return dispatch({
        type: SET_IS_LOADING_INSTAGRAM_PHOTO,
        isLoadingInstagramPhoto,
      });
    } else {
      return Promise.resolve();
    }
  };
};