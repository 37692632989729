import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import store from "./store";

// window.LOG_LEVEL = "VERBOSE";


// const useStyles = makeStyles(theme => ({
//   "@global": {
//     html: {
//       height: "100%",
//     },
//     body: {
//       height: "100%",
//       margin: 0,
//       fontFamily: "-apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif",
//       lineHeight: 1.5,
//       fontSize: 16,
//     },
//     "#root": {
//       height: "100%",
//     },
//     "h1, h2, h3, h4, h5, h6": {
//       margin: 0,
//       fontWeight: 500,
//     },
//     "h1": {
//       fontSize: 36,
//       lineHeight: "54px",
//       color: "black",
//     },
//     "h2": {
//       fontSize: 24,
//       lineHeight: "36px",
//       color: "black",
//     },
//   }
// }));

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff5c9d",
      main: "#ff006f",
      dark: "#c50045",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        button.MuiButton-root {
          min-width: 48px;
        }
      `,
    },
    // MuiButtonBase: {
    //   defaultProps: {
    //     disableRipple: true,
    //   },
    // },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<App />} />
            <Route path={":username"} element={<App />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();