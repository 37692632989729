import Bowser from "bowser";
import analytics from "./analytics";
import cache from "./cache";
import config from "../config";
import log from "./log";

const util = {};

const parser = Bowser.getParser(window.navigator.userAgent);

util.isDesktop = parser.is(Bowser.PLATFORMS_MAP.desktop);
util.isIOS = parser.is(Bowser.OS_MAP.iOS);
util.isChrome = parser.is(Bowser.BROWSER_MAP.chrome);

util.sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

util.navigateTo = (url, newTab = false, windowRef = null) => {
  // analytics.trackPageRedirect(url);
  if (windowRef) {
    windowRef.location = url;
  } else {
    const name = newTab ? "_blank" : "_self";
    window.open(url, name);
  }
};

util.navigateToActBlue = (amount, orderId, windowRef = null) => {
  const userId = cache.getRequired("userId");
  let url = `https://secure.actblue.com/donate/dumptrump-2020?express_lane=true&refcode=donate-step&refcode_user=${userId}`;
  if (orderId) {
    url += `&refcode_order=${orderId}`;
  }
  if (amount && amount !== "other") {
    url += `&amount=${amount}`;
  }
  util.navigateTo(url, true, windowRef);
};

util.formatPhoneNumber = phoneNumber => {
  const areaCode = phoneNumber.substring(2, 5);
  const prefix = phoneNumber.substring(5, 8);
  const lineNumber = phoneNumber.substring(8, 12);
  return `(${areaCode}) ${prefix}-${lineNumber}`;
};

util.pluralText = (count, pluralText, singularText) => count === 1 ? singularText : pluralText;

util.isString = value => (typeof value === "string" || value instanceof String);

util.isBool = value => (typeof variable === "boolean");

util.safeTrim = value => util.isString(value) ? value.trim() : value;

// Replace with null if undefined or an empty string
util.replaceWithNull = value => (value === undefined || value === "") ? null : value;

util.getEnvironment = () => {
  const userAgentInfo = parser.parsedResult;

  let timeZone = null;
  let locale = null;
  try {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    timeZone = resolvedOptions.timeZone;
    locale = resolvedOptions.locale;
  } catch (error) {
    log.error(error);
  }

  return {
    browserName: util.replaceWithNull(userAgentInfo.browser.name), // Browser name, like `"Chrome"` or `"Internet Explorer"`
    browserVersion: util.replaceWithNull(userAgentInfo.browser.version), // Browser version as a String `"12.01.45334.10"`
    osName: util.replaceWithNull(userAgentInfo.os.name), // OS name, like `"Windows"` or `"macOS"`
    osVersion: util.replaceWithNull(userAgentInfo.os.version), // OS version, like `"NT 5.1"` or `"10.11.1"`
    osVersionName: util.replaceWithNull(userAgentInfo.os.versionName), // OS name, like `"XP"` or `"High Sierra"`
    platformType: util.replaceWithNull(userAgentInfo.platform.type), // platform type, can be either `"desktop"`, `"tablet"` or `"mobile"`
    platformVendor: util.replaceWithNull(userAgentInfo.platform.vendor), // like `"Apple"` or `"Samsung"`
    platformModel: util.replaceWithNull(userAgentInfo.platform.model), // like `"iPhone"` or `"Kindle Fire HD 7"`
    engineName: util.replaceWithNull(userAgentInfo.engine.name), // Can be any of this: `WebKit`, `Blink`, `Gecko`, `Trident`, `Presto`, `EdgeHTML`
    engineVersion: util.replaceWithNull(userAgentInfo.engine.version), // String version of the engine
    timezoneOffset: -(new Date().getTimezoneOffset() / 60),
    timeZone: timeZone,
    locale: locale
  };
};

util.makeId = (length = 12) => {
  const charsFirst = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charsAll = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const buffer = new Uint32Array(length);
  crypto.getRandomValues(buffer);
  let result = new Array(length);
  let cursor = 0;
  for (let i = 0; i < length; i++) {
    cursor += buffer[i];
    result[i] = (i === 0 ? charsFirst[cursor % charsFirst.length] : charsAll[cursor % charsAll.length]);
  }
  return result.join("");
};

util.getAge = (dateOfBirth, targetDate) => {
  if (!targetDate) {
    targetDate = new Date();
  }

  let age = targetDate.getFullYear() - dateOfBirth.getFullYear();
  const m = targetDate.getMonth() - dateOfBirth.getMonth();
  if (m < 0 || (m === 0 && targetDate.getDate() < dateOfBirth.getDate())) {
      age--;
  }
  return age;
};

util.getCurrentYear = () => (new Date()).getFullYear();

util.getState = () => (cache.get("homeAddressProcessed") && cache.get("homeAddressProcessed").state) || cache.get("state") || (cache.get("shippingAddress") && cache.get("shippingAddress").state) || null;

util.getCity = () => (cache.get("homeAddressProcessed") && cache.get("homeAddressProcessed").city) || (cache.get("shippingAddress") && cache.get("shippingAddress").city) || null;

util.getZip = () => (cache.get("homeAddressProcessed") && cache.get("homeAddressProcessed").zip) || (cache.get("shippingAddress") && cache.get("shippingAddress").zip) || null;

export default util;