import React from "react";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Creators = ({ creators }) => {
  if (!creators || creators.length === 0) {
    return null;
  }

  return (
    <>
      <span> by </span>
      {creators.map((creator, index) => {
        const separator = index < creators.length - 2 ? ", " : index == creators.length - 2 ? " and " : "";
        return (
          <Box component="span" key={index}>
            {creator.username ? (
              <Link component={RouterLink} to={"/" + creator.username}>{creator.name}</Link>
            ) : (
              <span>{creator.name}</span>
            )}
            {separator}
          </Box>
        );
      })}
    </>
  );
};

export default Creators;