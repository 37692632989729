import React, { useState, useCallback } from "react";
import { Box, Button, Link } from "@mui/material";
import update from "immutability-helper";
import RecEditable from "./RecEditable";

const RecSectionEditable = ({ recSection, handleOpen }) => {
  const [recs, setRecs] = useState(recSection.recs);
  const [disabled, setDisabled] = useState(false);

  const addRec = useCallback((rec, index) => {
    if (index === null) {
      setRecs(prevRecs =>
        update(prevRecs, {
          $push: [rec]
        })
      );
    } else {
      setRecs(prevRecs =>
        update(prevRecs, {
          [index]: {
            $set: rec
          }
        })
      );
    }
  }, []);

  const removeRec = useCallback(index => {
    setRecs(prevRecs =>
      update(prevRecs, {
        $splice: [
          [index, 1]
        ]
      })
    );
  }, []);

  const moveRec = useCallback((dragIndex, hoverIndex) => {
    setRecs(prevRecs =>
      update(prevRecs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevRecs[dragIndex]]
        ]
      })
    );
  }, []);

  const renderRec = useCallback((rec, index) => {
    return (
      <RecEditable
        key={rec.mediaId}
        rec={rec}
        index={index}
        id={rec.mediaId}
        handleOpen={handleOpen}
        moveRec={moveRec}
        disabled={disabled}
        setDisabled={setDisabled}
        addRec={addRec}
        removeRec={removeRec}
      />
    );
  }, [disabled]);

  return (
    <>
      <Box component="h3" sx={{ textTransform: "uppercase" }}>{recSection.title}</Box>
      <Box component="ol" sx={{ pl: "40px" }}>
        {recs.map((rec, i) => renderRec(rec, i))}
      </Box>
      <Button onClick={() => handleOpen(recSection.typeId, null, null, addRec)} disabled={disabled} sx={{ ml: -1 }}>Add rec</Button>
    </>
  );
};

export default RecSectionEditable;