import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import throttle from "lodash/throttle";
import reducer from "./reducers/index";
import { loadState, saveState } from "./storage";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  loadState(),
  composeEnhancers(applyMiddleware(thunk, logger))
);
store.subscribe(throttle(() => saveState(store.getState()), 1000));

export default store;