import { log } from "./core";

export const loadState = () => {
  const session = loadStateHelper("sessionStorage");
  const storage = loadStateHelper("localStorage");

  const state = {};
  if (session) {
    state.session = session;
  }
  if (storage) {
    state.storage = storage;
  }
  return state;
};

export const saveState = state => {
  saveStateHelper(state.session, "sessionStorage");
  saveStateHelper(state.storage, "localStorage");
};

const loadStateHelper = storageType => {
  const storage = storageType === "localStorage" ? localStorage : sessionStorage;
  try {
    const serializedState = storage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    log.error(error);
    return undefined;
  }
};

const saveStateHelper = (state, storageType) => {
  const storage = storageType === "localStorage" ? localStorage : sessionStorage;
  try {
    if (state) {
      const serializedState = JSON.stringify(state);
      storage.setItem("state", serializedState);
    } else {
      storage.removeItem("state");
    }
  } catch (error) {
    log.error(error);
  }
};