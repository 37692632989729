import React from "react";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Home = ({ users }) => {
  return (
    <>
      <Box component="h2" sx={{ textTransform: "uppercase" }}>Rec</Box>
      <p>Learn what books and podcasts influenced the people who influence you.</p>
      <h3>Popular influencers</h3>
      <ul>
      {users.map((user, index) =>
        <Box component="li" key={index} mb={2}>
          <Link component={RouterLink} to={"/" + user.username}>{user.name}</Link>
        </Box>
      )}
      </ul>
    </>
  );
};

export default Home;