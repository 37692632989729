const getConfig1 = environment => ({
  awsRegion: "us-west-2",
  awsApiName: "api",
  twitterUsername: "dumptrumpsocial",
  twitterUrl: "https://twitter.com/dumptrumpsocial",
  facebookUrl: "https://www.facebook.com/dumptrumpsocial",
  instagramUrl: "https://www.instagram.com/dumptrumpsocial",
  shareText: "I am standing up to hate by holding Trump and his enablers accountable this November. Will you join me?",
  shareTextFriend: "I am standing up to hate by holding Trump and his enablers accountable this November. Will you join me?",
  shareBaseUrl: "https://rec.page/",
  shareHashtag: "dumptrump",
  electionDay: "2020-11-03",
  oldestReasonableAge: 150,
  experiment: {}
});

const getConfig2 = environment => {
  if (environment === "prod") {
    return {
      baseUrl: "https://rec.page",
      awsIdentityPoolId: "us-west-2:8a779476-5803-475a-83cd-94bddc47dccf",
      awsUserPoolId: "us-west-2_RIQbcqq7A",
      awsUserPoolWebClientId: "3emd6mcrdhc9pftgmmciotepu7",
      awsApiEndpoint: "https://api.rec.page",
      facebookPixelId: "473186073290446",
      googleTrackingId: "UA-157507513-3",
    };
  } else {
    return {
      baseUrl: "https://dev.rec.page",
      awsIdentityPoolId: "us-west-2:bbc592f2-7ad7-4a8d-8566-f6c3c3314c5b",
      awsUserPoolId: "us-west-2_QFJU75MfR",
      awsUserPoolWebClientId: "4qiqja032s6h9g994j7npvr286",
      awsApiEndpoint: "https://api-dev.rec.page",
      facebookPixelId: "2909439315770087",
      googleTrackingId: "UA-157507513-2",
    };
  }
};

const getConfig3 = environment => {
  if (environment === "local") {
    return {
      isRegisteredApiEnabled: true,
      isRegisteredApiResponse: true,
      verifyAddressApiEnabled: true,
    };
  } else {
    return {
      isRegisteredApiEnabled: true,
      isRegisteredApiResponse: false,
      verifyAddressApiEnabled: true,
    };
  }
};

export default {
  ...getConfig1(process.env.REACT_APP_ENVIRONMENT),
  ...getConfig2(process.env.REACT_APP_ENVIRONMENT),
  ...getConfig3(process.env.REACT_APP_ENVIRONMENT),
};