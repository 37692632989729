import React, { useEffect, useMemo, useState } from "react";
import { Box, TextField, Autocomplete, Grid, Typography } from "@mui/material";
import throttle from "lodash/throttle";
import { api } from "../core";

// https://mui.com/components/autocomplete/#GoogleMaps.js
const MediaSearch = ({ typeId, value2, onChange, ...props }) => {
  const [value, setValue] = useState(value2);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      throttle((inputValue, callback) => {
        api.searchMedia(typeId, inputValue)
          .then(result => {
            callback(result ? result.media : []);
          });
      }, 1000),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, media => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];

          // If we have a selected value, let's show that first in the results and remove
          // any new media results that would be a duplicate.
          const index = media.findIndex(m => m.mediaId == value.mediaId);
          if (index != -1) {
            media.splice(index, 1);
          }
        }

        if (media) {
          newOptions = [...newOptions, ...media];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={option => option.title}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      forcePopupIcon={false}
      value={value}
      onChange={(event, value) => {
        setOptions(value ? [value, ...options] : options);
        setValue(value);

        // HACK: The event here is whatever UI element the user is clicking on. I'm just adding
        // the ID and name of our input field and our desired value to that DOM element so that
        // formik's handleChange function works properly.
        const fakeEvent = {
          target: {
            id: props.id,
            name: props.name,
            value: value ? {mediaId: value.mediaId, title: value.title} : null
          }
        };
        onChange(fakeEvent);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} {...props} />
      )}
      renderOption={(props, media) => {
        return (
          <li {...props} key={media.mediaId}>
            <Grid container alignItems="center">
              <Grid item>
                {media.thumbnailUrl ? (
                  <Box
                    component="img"
                    sx={{
                      width: "35px",
                      backgroundColor: "#F0F0F0",
                      mr: 2
                    }}
                    alt="Media thumbnail"
                    src={media.thumbnailUrl}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "35px",
                      height: "58px",
                      backgroundColor: "#F0F0F0",
                      mr: 2
                    }}
                  />
                )}
              </Grid>
              <Grid item xs>
                <span>{media.title}</span>
                <Typography variant="body2" color="text.secondary">
                  {media.subtitle}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default MediaSearch;