import Cache from "@aws-amplify/cache";
import log from "./log";
console.log(log);

const cacheVersion = 3;

const cache = {};

const memoryCache = {};

const sessionCache = Cache.createInstance({
  keyPrefix: `rec${cacheVersion}-`,
  storage: window.sessionStorage,
  defaultTTL: 3628800000, // 3 weeks (default is 3 days)
});

const storageCache = Cache.createInstance({
  keyPrefix: `rec${cacheVersion}-`,
  storage: window.localStorage,
  defaultTTL: 3628800000, // 3 weeks (default is 3 days)
});

storageCache.getAllKeys().forEach(key => {
  memoryCache[key] = storageCache.getItem(key);
});
sessionCache.getAllKeys().forEach(key => {
  memoryCache[key] = sessionCache.getItem(key);
});
log.debug("Cache initialized: ", memoryCache);

cache.MEMORY = "MEMORY";
cache.SESSION = "SESSION";
cache.STORAGE = "STORAGE";

cache.set = (item, cacheType = cache.SESSION) => {
  Object.keys(item).forEach(key => {
    const value = item[key];
    if (memoryCache[key] !== value) {
      memoryCache[key] = value;

      if (cacheType === cache.SESSION) {
        sessionCache.setItem(key, value);
      } else if (cacheType === cache.STORAGE) {
        storageCache.setItem(key, value);
      }

      log.debug(`Cache updated: ${key} => ${value}`);
    }
  });
};

cache.get = key => memoryCache[key];

cache.getRequired = key => {
  if (cache.exists(key)) {
    return cache.get(key);
  } else {
    throw new Error(`Unable to find ${key} in the cache.`);
  }
};

cache.exists = (...keys) => {
  return !keys.some(key => cache.get(key) === undefined);
};

cache.remove = key => {
  delete memoryCache[key];
  sessionCache.removeItem(key);
  storageCache.removeItem(key);
  log.debug(`Cache updated: ${key} deleted`);
};

export default cache;