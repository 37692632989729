import Auth from "@aws-amplify/auth";
import { setIsAuthenticated } from "../actions";
import store from "../store";
import analytics from "./analytics";
import api from "./api";
import cache from "./cache";
import log from "./log";
import util from "./util";
import config from "../config";

const auth = {};

auth.login = async (identityId, token) => {
  if (await auth.fetchIsAuthenticated()) {
    log.debug("Already authenticated, logging out...");
    if (!await auth.logout()) {
      log.error("Failed to logout before logging in.");
      return false;
    }
  }

  try {
    const credentials = await Auth.federatedSignIn(
      "developer",
      {
        token,
        identity_id: identityId,
        expires_at: new Date().getTime() + 1000 * 60 * 5 // 5 mins
      });

    // window.localStorage.removeItem(`CognitoIdentityId-${config.awsIdentityPoolId}`);
    cache.set({ identityId }, cache.STORAGE);

    console.log(credentials);
    const user = await Auth.currentAuthenticatedUser();
    log.debug(user);

    return true;
  }
  catch (error) {
    log.error(error);
    return false;
  }
};

auth.refreshToken = async () => {
  try {
    const result = await fetch(config.awsApiEndpoint + "/refresh-token", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        identityId: cache.get("identityId"),
        userId: cache.get("user").userId
      })
    });
    const json = await result.json();
    return json.accessToken;
  }
  catch (error) {
    log.error(error);
    return null;
  }
};

// I could have sworn there was a way to go from an authenticated to unauthenticated with the same identity.
// However, I can't figure out how (I've tried unlink-developer-identity). My solution, for now, is to clear
// the cache so Amplify just fetches a new unauthenticated id.
// https://github.com/aws-amplify/amplify-js/blob/main/packages/core/src/Credentials.ts
// https://docs.amplify.aws/lib/auth/advanced/q/platform/js/
auth.logout = async () => {
  try {
    await Auth.signOut();

    const isAuthenticated = await auth.fetchIsAuthenticated();
    if (isAuthenticated) {
      log.error("Logout succeeded, but still autenticated.");
      return false;
    }

    const identityId = await auth.fetchIdentityId();
    if (!identityId) {
      throw new Error("Unable to get the identity ID (unauthenticated).");
    }
    cache.set({ identityId }, cache.STORAGE);

    store.dispatch(setIsAuthenticated(false, null));
    cache.remove("user");

    return true;
  } catch (error) {
    log.error(error);
    return false;
  }
};

auth.fetchAuthenticatedUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    if (error !== "The user is not authenticated") {
      log.error(error);
    }
    return null;
  }
};

auth.fetchCurrentSession = async () => {
  try {
    return await Auth.currentSession();
  } catch (error) {
    if (error !== "No current user") {
      log.error(error);
    }
    return null;
  }
};

auth.fetchIsAuthenticated = async () => {
  const isAuthenticated = await auth.fetchAuthenticatedUser() !== null;
  const identityId = await auth.fetchIdentityId();
  store.dispatch(setIsAuthenticated(isAuthenticated, identityId));
  cache.set({ identityId }, cache.STORAGE);
  return isAuthenticated;
};

auth.fetchIdentityId = async () => {
  const credentials = await auth.currentCredentials();
  cache.set({ identityId: credentials.identityId }, cache.STORAGE);
  return credentials.identityId;
};

// https://github.com/aws-amplify/amplify-js/issues/5696
auth.currentCredentials = async () => {
  log.debug("Fetching current credentials.");
  let credentials = await Auth.currentCredentials();
  // if (credentials?.message?.includes("Access to Identity") && credentials?.message?.includes("is forbidden")) {
  //   log.debug("Current credentials are not accessible, clearing cache.");
  //   window.localStorage.removeItem(`CognitoIdentityId-${config.awsIdentityPoolId}`);
  //   credentials = await Auth.currentCredentials();
  // }
  return credentials;
};

auth.fetchToken = async () => {
  const session = await auth.fetchCurrentSession();
  return session ? session.getIdToken().getJwtToken() : null;
};

auth.isAuthenticated = () => {
  const isAuthenticated = store.getState().memory.auth.isAuthenticated;
  if (isAuthenticated === null) {
    throw new Error("Need to first fetch authentication.");
  }
  return isAuthenticated;
};

// auth.userId = () => {
//   const userId = store.getState().memory.auth.userId;
//   if (userId === null) {
//     throw new Error("Not authenticated.");
//   }
//   return userId;
// };

// auth.fetchAuthUserInfo = async () => {
//   const result = await api.login();
//   if (result) {
//     const { userId, referralId, firstName, impact, referrals } = result;
//     auth.updateUserId(userId);
//     cache.set({ referralId, firstName }, cache.STORAGE);
//     cache.set({ impact, referrals }, cache.MEMORY);
//     return result;
//   } else {
//     return null;
//   }
// };

// auth.updateUserId = userId => {
//   if (userId !== cache.get("userId")) {
//     cache.set({ userId }, cache.STORAGE);
//     log.setUser(userId);
//     analytics.updateUserId(userId);
//   }
// };

export default auth;