import React from "react";
import { Box } from "@mui/material";
import Rec from "./Rec";

const RecSection = ({ recSection }) => {
  return (
    <>
      <Box component="h3" sx={{ textTransform: "uppercase" }}>{recSection.title}</Box>
      <Box component="ol">
        {recSection.recs.map(rec =>
          <Rec key={rec.mediaId} rec={rec} />
        )}
      </Box>
    </>
  );
};

export default RecSection;