import Amplify from "@aws-amplify/core";
import config from "../config";
import auth from "./auth";
import cache from "./cache";

const aws = {};

aws.initialize = () => {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: config.awsApiName,
          endpoint: config.awsApiEndpoint,
          region: config.awsRegion
        }
      ]
    },
    Auth: {
      identityPoolId: config.awsIdentityPoolId,
      region: config.awsRegion,
      userPoolId: config.awsUserPoolId,
      userPoolWebClientId: config.awsUserPoolWebClientId,
      refreshHandlers: {
        "developer": async () => {
          console.log("Refreshing credentials...");
          const token = await auth.refreshToken();
          return new Promise((resolve, reject) => {
            if (token) {
              const data = {
                token: token,
                identity_id: cache.get("identityId"),
                expires_at: new Date().getTime() + 1000 * 60 * 5 // 5 mins
              }
              resolve(data);
            } else {
              reject(new Error("mutations.refresh failed, [no error]"));
            }
          });





          // const result = await api.getAccessToken(null, null);
          // return new Promise((resolve, reject) => {
          //   if (result) {
          //     const data = {
          //       token: result.accessToken, // the token from the provider
          //       expires_at: new Date().getTime() + 1000 * 60 * 1, // 1 min
          //       identity_id: cache.get("userId") // optional, the identityId for the credentials
          //     }
          //     resolve(data);
          //   } else {
          //     reject(new Error("mutations.refresh failed, [no error]"));
          //   }
          // });
        }
      }
    }
  });
};

export default aws;