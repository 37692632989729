import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import Header from "./Header";
import Home from "./Home";
import Recs from "./Recs";
import { analytics, api, auth, aws, cache, log, util, Loader } from "../core";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";

const App = ({ isAuthenticated }) => {
  let params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  const username = params.username;

  useEffect(() => {
    (async () => {
      log.debug("Initializing...");
      aws.initialize();

      const state = searchParams.get("state");
      const code = searchParams.get("code");
      setSearchParams({});

      if (state && code) {
        const result = await api.getToken(state, code);
        if (result) {
          if (await auth.login(result.identityId, result.token)) {
            const user = {
              userId: result.userId,
              username: result.username,
              name: result.name,
            };
            // setUser(user);
            cache.set({ user }, cache.STORAGE);

            log.debug("Login success!");
            navigate("/" + user.username);
          } else {
            log.error("Failed to login.");
          }
        }
      } else if (state) {
        log.error("Failed to login.");
      }

      const isAuthenticated = await auth.fetchIsAuthenticated();

      setInitialized(true);
    })();
  }, []);

  useEffect(() => {    
    (async () => {
      if (!initialized) {
        return;
      }

      log.debug("Preparing...");

      setLoading(true);

      if (username) {
        const result = await api.getUser(username);
        if (result.user) {
          setUser(result.user);
        } else {
          console.log("BAD USERNAME");
        }
      } else {
        setUser(null);

        const result = await api.getUsers();
        if (result) {
          setUsers(result.users);
        }
      }

      // if (loading) {
        setLoading(false);
      // }
    })();
  }, [initialized, isAuthenticated, username]);

  // TouchBackend has some known issues with mobile Safari:
  // https://github.com/react-dnd/react-dnd/issues/2206
  // Solution might be to use a CustomDragLayer:
  // https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer
  // Although there's probably a way to do something similar to how the library handles the preview:
  // https://react-dnd.github.io/react-dnd/docs/api/drag-preview-image
  return (
    <DndProvider backend={HTML5Backend}>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ p: 4 }}>
          <Header isAuthenticated={isAuthenticated} />
          <Box sx={{ pt: 0, pb: 4 }}>
            {user ? (
              <Recs user={user} />
            ) : (
              <Home users={users} />
            )}
          </Box>
        </Box>
      )}
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.memory.auth.isAuthenticated
});

export default connect(mapStateToProps)(App);