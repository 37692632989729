import React from "react";
import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { api, auth, cache, util } from "../core";

// import { Autocomplete, Box, Button, Link, Input, IconButton, InputAdornment } from "@mui/material";

// const top100Films = [
//   "Peter Thiel",
//   "Yuval Noah Harari",
//   "ghi"
// ];


// <Autocomplete
//   options={top100Films}
//   freeSolo
//   renderInput={params => (
//     <Input
//       placeholder="Search Rec"
//       variant="filled"
//       inputProps={params.inputProps}
//       inputRef={params.InputProps.ref}
//       fullWidth
//       startAdornment={(
//         <InputAdornment position="start">
//           <SearchIcon />
//         </InputAdornment>
//       )}
//     />
//   )}
//   filterOptions={(x) => x}
// />

// <IconButton color="primary" aria-label="upload picture" component="span">
//   <SearchIcon />
// </IconButton>

const Header = ({ isAuthenticated }) => {
  const handleSearch = async () => {
    const result = await api.search("pasta");
    console.log(result);
  };

  const handleSignIn = async () => {
    if (isAuthenticated) {
      auth.logout();
    } else {
      const result = await api.getAuthLink();
      if (result) {
        util.navigateTo(result.url);
      } else {
        throw new Error("Unable to get the auth link.");
      }
    }
  };

  const gridTemplateColumns = isAuthenticated ? "auto auto auto" : "auto auto";
  const signInText = isAuthenticated ? "Sign out" : "Sign in with Twitter";
  const user = cache.get("user");

  return (
    <Box sx={{ display: "grid", gridTemplateColumns, pb: 2 }}>
      <Box><Button onClick={handleSearch} sx={{ ml: -1 }}>Search</Button></Box>
      {isAuthenticated &&
        <Box sx={{ textAlign: "center" }}><Button component={RouterLink} to={"/" + user.username}>My recs</Button></Box>
      }
      <Box sx={{ textAlign: "right" }}><Button onClick={handleSignIn} sx={{ mr: -1 }}>{signInText}</Button></Box>
    </Box>
  );
};

export default Header;