import React from "react";
import { Button as MuiButton, CircularProgress, IconButton as MuiIconButton, Link as MuiLink } from "@mui/material";
import clsx from "clsx";
import { analytics } from "../core";

// const useStyles = makeStyles(theme => ({
//   buttonWrapper: {
//     position: "relative",
//     display: "flex",
//   },
//   buttonProgress: {
//     color: "white",
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     marginTop: -14,
//     marginLeft: -14,
//   },
//   loader: {
//     backgroundColor: "white",
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   }
// }));

// export const Button = React.forwardRef(({ id, navigationId, onClick, buttonStyle, loading, children, ...props }, ref) => {
//   if (buttonStyle === "primary") {
//     props.variant = "contained";
//     props.color = "secondary";
//     props.fullWidth = true;
//   } else if (buttonStyle === "secondary") {
//     props.variant = "contained";
//     props.fullWidth = true;
//   } else if (buttonStyle === "link") {
//     props.fullWidth = true;
//   }

//   const handleClick = event => {
//     analytics.trackButtonTap(id);
//     if (onClick && navigationId) {
//       onClick(navigationId, event);
//     } else if (onClick) {
//       onClick(event);
//     }
//   };

//   return (
//     <div className={classes.buttonWrapper}>
//       <MuiButton
//         id={id}
//         onClick={handleClick}
//         ref={ref}
//         disableElevation
//         {...props}
//       >
//         {children}
//       </MuiButton>
//       {loading && <CircularProgress size={28} className={classes.buttonProgress} />}
//     </div>
//   );
// });

// export const Link = ({ id, navigationId, onClick, children, ...props }) => {
//   const handleClick = event => {
//     analytics.trackButtonTap(id);
//     if (onClick && navigationId) {
//       onClick(navigationId, event);
//     } else if (onClick) {
//       onClick(event);
//     }
//   };

//   return (
//     <MuiLink
//       color="secondary"
//       id={id}
//       onClick={handleClick}
//       {...props}
//     >
//       {children}
//     </MuiLink>
//   );
// };

// export const IconButton = ({ id, navigationId, onClick, children, ...props }) => {
//   const handleClick = event => {
//     analytics.trackButtonTap(id);
//     if (onClick && navigationId) {
//       onClick(navigationId, event);
//     } else if (onClick) {
//       onClick(event);
//     }
//   };

//   return (
//     <MuiIconButton
//       id={id}
//       onClick={handleClick}
//       {...props}
//     >
//       {children}
//     </MuiIconButton>
//   );
// };

// export const YesButton = props => <Button id="yes" navigationId="yes" buttonStyle="primary" {...props}>Yes</Button>;
// export const NoButton = props => <Button id="no" navigationId="no" buttonStyle="primary" {...props}>No</Button>;
// export const NotSureButton = props => <Button id="notSure" navigationId="notSure" buttonStyle="primary" {...props}>I’m not sure</Button>;
// export const ContinueButton = props => <Button id="continue" navigationId="continue" buttonStyle="primary" {...props}>Continue</Button>;
// export const ShareButton = props => <Button id="share" navigationId="share" buttonStyle="primary" {...props}>Share</Button>;
// export const UpdateButton = props => <Button id="update" navigationId="update" buttonStyle="primary" {...props}>Update</Button>;
// export const DoneButton = props => <Button id="done" navigationId="done" buttonStyle="primary" {...props}>Done</Button>;
// export const SkipButton = props => <Button id="skip" navigationId="skip" buttonStyle="secondary" {...props}>Skip</Button>;
// export const DownloadButton = React.forwardRef((props, ref) => <Button id="download" navigationId="download" buttonStyle="primary" ref={ref} {...props}>Download</Button>);

export const Loader = props => {
  return (
    // <div className={clsx(classes.loader, "loader")}>
    <div className="loader">
      <CircularProgress color="secondary" {...props} />
    </div>
  );
};