import log from "./log";
import util from "./util";

const share = {};

share.canShare = () => navigator.share && !util.isDesktop;

share.canShareFiles = () => navigator.share && navigator.canShare && !util.isDesktop;

share.share = async data => {
  try {
    await navigator.share(data);
  } catch(error) {
    log.error(error);
  }
};

share.shareFiles = async data => {
  try {
    await navigator.share(data);
  } catch(error) {
    log.error(error);
  }
};

// TODO: Look into intent based sharing
// https://developer.chrome.com/multidevice/android/intents
// https://www.chromium.org/developers/web-intents-in-chrome
// https://developers.facebook.com/docs/instagram/sharing-to-feed
// <a href="intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end">Intent</a>

export default share;