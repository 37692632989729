import API from "@aws-amplify/api";
import config from "../config";
import auth from "./auth";
import log from "./log";

const api = {};

api.addRec = async (existingMediaId, mediaId, description) => {
  return await invokeApi("add-rec", { existingMediaId, mediaId, description }, true);
};

api.getAuthLink = async () => {
  return await invokeApi("get-auth-link");
};

api.getToken = async (state, code) => {
  return await invokeApi("get-token", { state, code });
};

api.getUser = async username => {
  return await invokeApi("get-user", { username });
};

api.getUsers = async () => {
  return await invokeApi("get-users");
};

api.moveRec = async (mediaId, index) => {
  return await invokeApi("move-rec", { mediaId, index }, true);
};

api.removeRec = async mediaId => {
  return await invokeApi("remove-rec", { mediaId }, true);
};

api.search = async query => {
  return await invokeApi("search", { query });
};

api.searchMedia = async (typeId, query) => {
  return await invokeApi("search-media", { typeId, query }, true);
};

const invokeApi = async (endpoint, body = {}, authRequired = false) => {
  // if (authRequired) {
  //   if (!auth.isAuthenticated()) {
  //     throw new Error("User must be authenticated to call this API.");
  //   }
  // }

  log.debug(`Calling ${endpoint} endpoint...`, body);
  try {
    const result = await API.post("api", "/" + endpoint, { body }) || {};
    log.debug(result);
    return result;
  } catch (error) {
    log.error(error);
    return null;
  }
};

export default api;