import { combineReducers } from "redux";

import {
  SET_IS_AUTHENTICATED,
  SET_IS_LOADING_INSTAGRAM_PHOTO
} from "../actions";

const auth = (state = {
  isAuthenticated: null,
  identityId: null,
}, action) => {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return Object.assign({}, state, {
        isAuthenticated: action.isAuthenticated,
        identityId: action.identityId
      });
    default:
      return state;
  }
};

const photo = (state = {
  isLoadingInstagramPhoto: false,
}, action) => {
  switch (action.type) {
    case SET_IS_LOADING_INSTAGRAM_PHOTO:
      return Object.assign({}, state, {
        isLoadingInstagramPhoto: action.isLoadingInstagramPhoto
      });
    default:
      return state;
  }
};

export default combineReducers({
  auth,
  photo
});