import React, { useState } from "react";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { auth, cache } from "../core";
import RecSection from "./RecSection";
import RecSectionEditable from "./RecSectionEditable";
import AddRecDialog from "./AddRecDialog";

const Recs = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [typeId, setTypeId] = useState(null);
  const [rec, setRec] = useState(null);
  const [index, setIndex] = useState(null);
  const [addRec, setAddRec] = useState(null);

  const handleOpen = (typeId, rec, index, addRec) => {
    setTypeId(typeId);
    setRec(rec);
    setIndex(index);
    setAddRec(() => addRec);
    setOpen(true);
  };

  const handleClose = (newRec, index) => {
    if (newRec) {
      addRec(newRec, index);
    }
    setOpen(false);
  };

  const isEditable = auth.isAuthenticated() && user.userId === cache.get("user").userId;

  const types = {
    book: {
      title: "Books"
    },
    podcast: {
      title: "Podcasts"
    }
  };

  const recSections = [];

  ["book", "podcast"].forEach(typeId => {
    if (user.recSections[typeId] || isEditable) {
      const recSection = {
        typeId,
        title: types[typeId].title,
        recs: user.recSections[typeId] || []
      };
      console.log(recSection);
      recSections.push(recSection);
    }
  });

  // <Link component={RouterLink} to="/">Rec</Link> / {user.name}
  // {user.name}’s Recs

  return (
    <>
      <Box component="h2" sx={{ mb: "43px", textTransform: "uppercase" }}>
        <Link component={RouterLink} to="/">Rec</Link> / {user.name}
      </Box>
      {recSections.length === 0 && !isEditable ? (
        <Box>
          <h3>No recs 😭</h3>
          <p>Want to know their recs? <Link>Send a tweet</Link>.</p>
          {!auth.isAuthenticated() &&
            <p>Are you {user.name}? <Link>Sign in with Twitter to add your recs</Link>.</p>
          }
        </Box>
      ) : (
        <>
          {recSections.map(recSection => {
            return isEditable ? (
              <RecSectionEditable key={recSection.typeId} recSection={recSection} handleOpen={handleOpen} />
            ) : (
              <RecSection key={recSection.typeId} recSection={recSection} />
            )
          })}
          {isEditable &&
            <AddRecDialog typeId={typeId} rec={rec} index={index} open={open} handleClose={handleClose} />
          }
        </>
      )}
    </>
  );
};

export default Recs;